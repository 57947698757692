<script setup lang="ts">

</script>

<template>
  <Body>
    <slot />
  </Body>
</template>

<style scoped lang="scss">

</style>
